(function(){
    window.addEventListener('hashchange', function(e){
        updatePage();
    });

    window.addEventListener('onLocalStorageChange', function(e){
        updateUser();
        updatePage();
    });

    reset();

    window.dumbleDataHelpers = {
      updateSchoolYear: updateSchoolYear,
      updateCurrentSchoolYear: updateCurrentSchoolYear,
      updateOrgData: updateOrgData,
      updateStudentClassData: updateStudentClassData,
      updateUser: updateUser,
      reset: reset,
    };

    function updateSchoolYear(calendar) {
      if (window.dumbleData.user.schoolYear) {
        window.dumbleData.user.schoolYear.startDate = calendar.startDate;
        window.dumbleData.user.schoolYear.endDate = calendar.endDate;
        window.dumbleData.user.schoolYear.calendarId = calendar.id;
        return;
      }

      window.dumbleData.user.schoolYear = {
        startDate: calendar.startDate,
        endDate: calendar.endDate,
        calendarId: calendar.id
      };
    }

    function updateCurrentSchoolYear(currentSchoolYear) {
        var description = currentSchoolYear.description.split(' ')[0];

        if (window.dumbleData.user.schoolYear) {
            window.dumbleData.user.schoolYear.currentSchoolYear = description;
            return;
        }

        window.dumbleData.user.schoolYear = {
            currentSchoolYear: description
        };
    }

    function updateOrgData(org) {
      window.dumbleData.user.districtId = org.parent;
      window.dumbleData.user.schoolId = org.id;
    }

    function updateStudentClassData(classSections) {
      var data = classSections.reduce(function(previous, current) {
        previous.classIds.push(current.id);
        previous.teacherIds.push(current.staff.primaryTeacherId);
        return previous;
      }, { classIds: [], teacherIds: []});

      window.dumbleData.user.classId = data.classIds;
      window.dumbleData.user.teacherSdmId = data.teacherIds;
    }

    //helper functions
    function updatePage() {
        window.dumbleData.page.domain = window.location.hostname;
        window.dumbleData.page.destinationURL = window.location.href;
        fetch(window.location.href, {method: 'head'}).then(function(response){
            if(response.status==404){
                window.dumbleData.page.isError = true;
            } else{
                window.dumbleData.page.isError = false;
            }
        });
        if(navigator.language.includes('en')){
            window.dumbleData.page.language = 'english';
        }else if(navigator.language.includes('es')){
            window.dumbleData.page.language = 'spanish';
        }else{
            window.dumbleData.page.language = null;
        }
        window.dumbleData.page.pageName = ((window.location.href).substring((window.location.href).indexOf('#')+2)).replace(/\//g, ' ');
        if (!!localStorage.getItem('dpCurrentRole')  && localStorage.getItem('dpCurrentRole') == 'student') {
            window.dumbleData.page.siteSection = null;
        } else {
            var section = (window.location.href).substring((window.location.href).indexOf('#') + 2);
            if (section.includes('/')) {
                section = section.substring(0, section.indexOf('/'));
            }
            switch (section) {
                case 'signin':
                    window.dumbleData.page.siteSection = 'sign in';
                    window.dumbleData.page.pageTemplate = null;
                    break;
                case 'products':
                    window.dumbleData.page.siteSection = 'teacher dashboard';
                    window.dumbleData.page.pageTemplate = 'products';
                    break;
                case 'subscriptions':
                    window.dumbleData.page.siteSection = 'administrator dashboard';
                    window.dumbleData.page.pageTemplate = 'manage subscriptions';
                    break;
                case 'students':
                    window.dumbleData.page.siteSection = 'student dashboard';
                    window.dumbleData.page.pageTemplate = 'manage classes';
                    break;
                default:
                    window.dumbleData.page.siteSection = null;
                    window.dumbleData.page.pageTemplate = null;
            }
        }
    }

    function updateUser() {
        if (!!localStorage.getItem('dpUserContext')) {
            window.dumbleData.user.loginStatus = true;
            var role = localStorage.getItem('dpCurrentRole');
            switch (role) {
                case 'teacher':
                    window.dumbleData.user.userRole = 'educator';
                    break;
                default:
                    window.dumbleData.user.userRole = role;
            }
            var uc;
            try{
                uc = JSON.parse(localStorage.getItem('dpUserContext'));
            }catch(e){
                uc = {};
            }
            var authType = uc.sessionContext ? uc.sessionContext.sessionType : null;
            switch(authType){
                case 'DAS':
                    window.dumbleData.user.authenticationType = 'SDM';
                    break;
                case 'google':
                    window.dumbleData.user.authenticationType = 'Google';
                    break;
                case 'openam':
                    window.dumbleData.user.authenticationType = 'Clever';
                    break;
                default:
                    window.dumbleData.user.authenticationType = 'site auth';
                    authType = 'site auth';
            }

            var isStudent = localStorage.getItem("dpCurrentRole") === "student";

            if (isStudent) {
              if (
                !!uc.userIdentifiers &&
                !!uc.userIdentifiers.studentId &&
                authType !== "site auth"
              ) {
                window.dumbleData.user.sdmId = uc.userIdentifiers.studentId;
                window.dumbleData.user.grade = uc.student.grade;
              } else {
                window.dumbleData.user.sdmId = null;
                window.dumbleData.user.grade = null;
              }
            } else {
              window.dumbleData.user.sdmId =
                uc.userIdentifiers &&
                uc.userIdentifiers.staffId &&
                authType !== "site auth"
                  ? uc.userIdentifiers.staffId
                  : null;
            }

            window.dumbleData.user.curriculumResources = [];        //reliant on api
            window.dumbleData.user.CMProducts = [];                 //reliant on api
            if (!!localStorage.getItem('dpOrgId')) {
                window.dumbleData.user.schoolId = localStorage.getItem('dpOrgId');  //district reliant on api
            } else {
                window.dumbleData.user.districtId = null;
                window.dumbleData.user.schoolId = null;
            }
        } else {
            window.dumbleData.user.loginStatus = false;
            window.dumbleData.user.userRole = null;
            window.dumbleData.user.districtId = null;
            window.dumbleData.user.schoolId = null;
            window.dumbleData.user.sdmId = null;
            window.dumbleData.user.teacherSdmId = null;
            window.dumbleData.user.classId = null;
            window.dumbleData.user.grade = null;
            window.dumbleData.user.authenticationType = null;
            window.dumbleData.user.CMProducts = [];
            window.dumbleData.user.curriculumResources = [];
        }
    }

    function reset() {
      window.dumbleData = window.dumbleData || {};
      dumbleData.version = "1.0";
      dumbleData.siteInfo = dumbleData.siteInfo || {};
      dumbleData.siteInfo.name = "Scholastic Digital Manager";
      dumbleData.siteInfo.environment = (navigator.userAgent).includes("Mobile") ? "Mobile" : "Desktop";
      dumbleData.siteInfo.sysEnv = (location.href).includes("digital.scholastic.com") ? "production" : "dev";
      dumbleData.page = dumbleData.page || {};

      dumbleData.page.domain=null;
      dumbleData.page.destinationURL=null;
      dumbleData.page.isError=null;
      dumbleData.page.language=null;
      dumbleData.page.pageName=null;
      dumbleData.page.siteSection=null;
      dumbleData.page.pageTemplate=null;

      dumbleData.user = dumbleData.user || {};
      dumbleData.user.loginStatus=false;
      dumbleData.user.userRole=null;
      dumbleData.user.districtId=null;
      dumbleData.user.schoolId=null;
      dumbleData.user.teacherSdmId=[];
      dumbleData.user.classId=[];
      dumbleData.user.grade=null;
      dumbleData.user.authenticationType=null;
      dumbleData.user.CMProducts=[];
      dumbleData.user.curriculumResources=[];
    }
}());
